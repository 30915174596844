<template>
	<div>
		<div class="subSectionTitleWithoutborder">
			<span>🏖️ Je pars en vacances</span>
		</div>
		<a @click="openVacationModal"><span>Mettre en pause ma formation</span></a>

		<!-- Modal Mode Vacances -->
		<v-dialog v-model="vacationModal" max-width="500px">
			<v-card>
				<v-card-title class="headline">Mode Vacances 🏝️</v-card-title>
				<v-card-text>
					<p>
						En activant le mode vacances, vous allez désactiver temporairement les emails automatiques de l'application.
					</p>
					<p>N'oubliez pas de les réactiver à votre retour pour continuer à profiter pleinement de votre formation.</p>
					<p><strong>Nous vous souhaitons d'excellentes vacances !</strong></p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="#15c39a" dark @click="activateVacationMode" :loading="emailSubscriptionLoading"
						>Activer le mode vacances</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	name: 'VacationModeComponent',
	props: {
		// Récupérer l'information si l'utilisateur est abonné aux emails ou non
		subscribed: {
			type: Boolean,
			required: true,
		},
		// ID de l'entreprise pour la condition d'affichage
		companyId: {
			type: String,
			default: '',
		},
		// ID d'entreprise à exclure
		excludedCompanyId: {
			type: String,
			default: '5f6c994eac759a00173cef92',
		},
	},
	data() {
		return {
			vacationModal: false,
			emailSubscriptionLoading: false,
		};
	},
	methods: {
		openVacationModal() {
			this.vacationModal = true;
		},

		async activateVacationMode() {
			// Désactiver les emails automatiques
			if (this.subscribed) {
				this.emailSubscriptionLoading = true;
				try {
					// Envoyer l'action au store pour désactiver les emails
					await this.$store.dispatch('profile/toggleEmailSubscription', {
						subscribed: false,
					});

					// Emettre un événement pour informer le parent que l'abonnement a été désactivé
					this.$emit('subscription-changed', false);

					// Afficher un message de confirmation
					this.$emit('show-notification', 'Mode vacances activé ! Bonnes vacances 🏝️');
					this.vacationModal = false;
				} catch (error) {
					console.error("Erreur lors de l'activation du mode vacances:", error);
					this.$emit('show-notification', 'Une erreur est survenue. Veuillez réessayer.');
				} finally {
					this.emailSubscriptionLoading = false;
				}
			} else {
				// Les emails sont déjà désactivés
				this.$emit('show-notification', 'Les emails sont déjà désactivés. Bonnes vacances ! 🏝️');
				this.vacationModal = false;
			}
		},
	},
};
</script>

<style scoped>
.subSectionTitleWithoutborder {
	font-weight: bold;
	margin-bottom: 30px;
	color: grey;
}
</style>
