<template>
	<div>
		<v-switch
			:loading="loading"
			:value="allowNotification"
			:label="`Activer les notifications : ${allowNotification === true ? 'Activé' : 'Désactivé'}`"
			@click="handleSwitchClick"
			color="black"
			readonly
		/>
		<div v-if="dialog" class="text-center">
			<v-dialog v-model="dialog" max-width="500">
				<v-card class="px-4 py-4">
					{{ unblockInstruction }}
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getMessaging, getToken } from 'firebase/messaging';
import { PUBLIC_VAPID_KEY } from '@/config/firebase-messaging.js';

export default {
	name: 'AllowPushNotificationSetting',
	computed: {
		...mapGetters('profile', { profileInfos: 'profileInfos', formationProgress: 'formationProgress' }),
	},
	data() {
		return {
			allowNotification: null,
			loading: false,
			dialog: false,
			unblockInstruction: '',
		};
	},
	methods: {
		async handleSwitchClick() {
			this.loading = true;
			if (this.allowNotification === true) await this.turnOffNotification();
			else if (this.allowNotification === false) await this.turnOnNotification();
			this.loading = false;
		},
		detectBrowser() {
			const userAgent = navigator.userAgent;

			if (userAgent.includes('Chrome') && !userAgent.includes('Edge') && !userAgent.includes('OPR')) {
				return 'Chrome';
			} else if (userAgent.includes('Firefox')) {
				return 'Firefox';
			} else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
				return 'Safari';
			} else if (userAgent.includes('Edg')) {
				return 'Edge';
			} else if (userAgent.includes('OPR') || userAgent.includes('Opera')) {
				return 'Opera';
			} else {
				return 'Other';
			}
		},
		showUnblockInstructions() {
			const browser = this.detectBrowser();
			let instruction = '';
			if (browser === 'Chrome') {
				instruction = `Vous avez bloqué les notifications pour ce site. Pour activer les notifications : dans Chrome, allez dans Paramètres > Confidentialité et sécurité > Paramètres des sites > Notifications et débloquez ce site.`;
			} else if (browser === 'Firefox') {
				instruction = `Vous avez bloqué les notifications pour ce site. Pour activer les notifications : dans Firefox, allez dans Paramètres > Vie privée et sécurité > Permissions > Notifications, puis autorisez les notifications pour ce site.`;
			} else if (browser === 'Safari') {
				instruction = `Vous avez bloqué les notifications pour ce site. Pour activer les notifications : dans Safari, allez dans Préférences > Sites web > Notifications et modifiez les paramètres pour ce site.`;
			} else {
				instruction = `Vous avez bloqué les notifications pour ce site. Pour activer les notifications : veuillez vérifier les paramètres de votre navigateur pour débloquer les notifications.`;
			}
			this.unblockInstruction = instruction;
			this.dialog = true;
		},
		async turnOnNotification() {
			const permission = await Notification.requestPermission();

			if (permission === 'granted') {
				const messaging = getMessaging();
				const token = await getToken(messaging, { vapidKey: PUBLIC_VAPID_KEY });

				await this.$store.dispatch('profile/turnOnPushNotification', { newToken: token });
				this.allowNotification = true;
			} else if (permission === 'denied') {
				this.showUnblockInstructions();
			}
		},
		async turnOffNotification() {
			await this.$store.dispatch('profile/turnOffPushNotification');
			this.allowNotification = false;
		},
	},
	mounted() {
		this.allowNotification =
			this.profileInfos.allow_push_notification != null ? this.profileInfos.allow_push_notification : false;
	},
	watch: {
		profileInfos() {
			this.allowNotification =
				this.profileInfos.allow_push_notification != null ? this.profileInfos.allow_push_notification : false;
		},
	},
};
</script>

<style scoped lang="scss"></style>
